import { useState, useEffect, Fragment, MouseEvent } from 'react'
import { Dropdown } from '@mui/base/Dropdown'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { CirclePlus, Info, Pencil, X } from 'lucide-react'
import Box from '@mui/material/Box'
import OrganizationLocationForm from '@/components/organization-settings-and-members/organization-location-form.tsx'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  createOrganizationLocation,
  deleteOrganizationLocation,
  updateOrganizationLocation,
} from '@/api/organization_management.ts'
import {
  ContactEmail,
  ContactPhone,
  CreateOrganizationLocationRequestBody,
  Organization,
  OrganizationLocation,
} from '@/types.ts'
import { OrganizationLocationSchema } from '@/lib/validation-schemas.ts'
import { trimValues } from '@/lib/utils.ts'
import { z } from 'zod'
import { initialFormLocation } from '@/seed_form_data.ts'

type LocationsProps = {
  organization: Organization | undefined
  refetch_organization: () => void
}

export default function Locations({
  organization,
  refetch_organization,
}: LocationsProps) {
  const queryClient = useQueryClient()
  const [locations, setLocations] = useState(
    organization?.locations || [initialFormLocation]
  )
  const [validationError, setValidationError] = useState<string | null>(null)
  const [organizationLocationForm, setOrganizationLocationForm] = useState('')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [currentLocationId, setCurrentLocationId] = useState<string | null>(
    null
  )

  useEffect(() => {
    if (organization?.locations) {
      setLocations(organization.locations)
    }
  }, [organization])

  // Mutations
  const createLocationDetailsMutation = useMutation({
    mutationFn: createOrganizationLocation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['new-location'],
      })
      refetch_organization()
      setOrganizationLocationForm('')
    },
  })

  const updateLocationDetailsMutation = useMutation({
    mutationFn: updateOrganizationLocation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['update-location'] /* find significance of this */,
      })
      refetch_organization()
      setOrganizationLocationForm('')
    },
  })

  const deleteLocationMutation = useMutation({
    mutationFn: deleteOrganizationLocation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['delete-location'],
      })
      refetch_organization()
      setOrganizationLocationForm('')
    },
  })

  // Event Handlers
  const handleClick = (
    event: MouseEvent<HTMLElement>,
    locationId: string | null
  ) => {
    setAnchorEl(event.currentTarget)
    setCurrentLocationId(locationId) // Set the current location ID
  }

  const handleClose = () => {
    setAnchorEl(null)
    setCurrentLocationId(null)
  }

  const handleEditLocation = (locationId: string) => {
    setAnchorEl(null)
    toggleOrganizationLocationForm(locationId)
  }

  const toggleOrganizationLocationForm = (locationId: string) => {
    setOrganizationLocationForm(locationId)
    setValidationError(null)
  }

  const validateForm = (
    data: CreateOrganizationLocationRequestBody
  ): boolean => {
    try {
      const schema = OrganizationLocationSchema
      schema.parse(data)
      setValidationError(null)
      return true
    } catch (error) {
      if (error instanceof z.ZodError) {
        setValidationError(error.errors[0].message)
      } else {
        setValidationError('An unknown error occurred')
      }
      return false
    }
  }

  const cleanseLocationFormData = (
    location: OrganizationLocation | CreateOrganizationLocationRequestBody
  ) => {
    const requestData = trimValues(location)
    // remove empty phone numbers
    requestData.phone = requestData.phone.filter(
      (phone: ContactPhone) => phone.number
    )
    // remove empty email addresses
    requestData.email = requestData.email.filter(
      (email: ContactEmail) => email.address
    )
    return requestData
  }

  const submitLocationDetails = async (
    location: OrganizationLocation | CreateOrganizationLocationRequestBody
  ) => {
    // call mutation to save location details
    const requestData = cleanseLocationFormData(location)
    requestData.organization = organization?.id

    if (validateForm(requestData)) {
      try {
        await createLocationDetailsMutation.mutateAsync(requestData)
      } catch (error) {
        console.error('Error saving location:', error)
        setValidationError(
          `Failed to save location. Please try again. ${error}`
        )
      }
    }
  }

  const updateLocationDetails = async (
    location: OrganizationLocation | CreateOrganizationLocationRequestBody
  ) => {
    // call mutation to update location details
    const requestData = cleanseLocationFormData(location)
    if (typeof location?.organization === 'object') {
      requestData.organization = location.organization?.id || null
    } else {
      requestData.organization = location.organization || null
    }

    if (validateForm(requestData)) {
      try {
        await updateLocationDetailsMutation.mutateAsync(requestData)
      } catch (error) {
        console.error('Error saving location:', error)
        setValidationError(
          `Failed to save location. Please try again. ${error}`
        )
      }
    }
  }

  const handleRemoveLocation = async (locationId: string) => {
    setAnchorEl(null)
    console.log('Remove location', locationId)

    try {
      await deleteLocationMutation.mutateAsync(locationId)
    } catch (error) {
      console.error('Error removing location:', error)
      setValidationError(
        `Failed to remove location. Please try again. ${error}`
      )
    }
  }

  const locationHamburgerMenu = (locationId: string) => {
    return (
      <Dropdown key={locationId}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(event) => handleClick(event, locationId)} // Pass location id to handleClick
          sx={{ border: 1, borderRadius: 1, paddingX: 0, paddingY: 0.5 }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && currentLocationId === locationId} // Ensure menu opens only for current location
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            sx={{ margin: 1, borderRadius: 1 }}
            onClick={() => handleEditLocation(locationId)}
          >
            <Pencil className="mr-4" /> Edit Location Details
          </MenuItem>
          <MenuItem
            sx={{ margin: 1, borderRadius: 1 }}
            onClick={() => handleRemoveLocation(locationId)}
          >
            <X className="mr-4" /> Remove Location
          </MenuItem>
        </Menu>
      </Dropdown>
    )
  }

  return (
    <Box className="p-4">
      {/* section title and add_location button */}
      <div className="flex items-center justify-between border-b pb-4">
        <h2 className="text-lg font-semibold">Locations</h2>
        <button
          onClick={() => toggleOrganizationLocationForm('new-location')}
          className={`${organizationLocationForm ? 'opacity-25' : 'opacity-1'} flex items-center rounded-md bg-black px-4 py-3 text-sm text-white hover:bg-gray-800`}
        >
          <CirclePlus className="mr-2" size="16" /> Add Location
        </button>
      </div>

      {/* add location form */}
      {organizationLocationForm == 'new-location' ? (
        <OrganizationLocationForm
          validationError={validationError}
          location={initialFormLocation}
          toggleLocationDetails={toggleOrganizationLocationForm}
          submitLocationDetails={submitLocationDetails}
        />
      ) : null}

      {/* no locations message */}
      {locations?.length === 0 && !organizationLocationForm && (
        <div className="flex justify-center py-16">
          <p className="flex gap-4 rounded border p-4 text-sm text-gray-600">
            <Info /> No locations available for this organization
          </p>
        </div>
      )}

      {/* location list */}
      {locations
        ?.filter((location) => !location.deleted_at) // Filter out deleted locations
        .map((location, index) => (
          <Fragment key={location.id}>
            {organizationLocationForm === location.id ? (
              <OrganizationLocationForm
                key={index}
                location={location}
                validationError={validationError}
                toggleLocationDetails={toggleOrganizationLocationForm}
                submitLocationDetails={updateLocationDetails}
              />
            ) : (
              <div
                key={index}
                className="flex items-start justify-between border-b py-8"
              >
                <div className="flex w-full flex-col gap-4">
                  <div className="flex">
                    <div className="flex-1 pl-3">
                      <div className="pb-2 text-lg font-medium capitalize">
                        {location?.address
                          ? location.address?.address_type
                          : ''}{' '}
                        : {location.name ? location.name : 'n/a'}
                      </div>
                      <div className="text-sm text-gray-600">
                        <strong>Address</strong>
                        <p>
                          {location.address
                            ? location.address.address?.street
                            : ''}
                        </p>
                        <p>
                          {[
                            location?.address?.address?.city,
                            location?.address?.address?.state,
                            location?.address?.address?.zip,
                          ]
                            .filter(Boolean)
                            .join(', ')}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="flex flex-col gap-2">
                        <div className="text-sm text-gray-600">
                          <strong>Phone</strong>
                          {location.phone && location.phone.length > 0 ? (
                            location.phone?.map((phone, index) => (
                              <p key={index}>{phone.number}</p>
                            ))
                          ) : (
                            <p>n/a</p>
                          )}
                        </div>
                        <div className="mt-1 text-sm text-gray-600">
                          <strong>Email</strong>
                          {location.email && location.email.length > 0 ? (
                            location.email.map((email, index) => (
                              <p key={index}>{email.address || 'n/a'}</p>
                            ))
                          ) : (
                            <p>n/a</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {locationHamburgerMenu(location?.id || '')}
              </div>
            )}
          </Fragment>
        ))}
    </Box>
  )
}
