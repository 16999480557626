import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { RouterProvider } from '@tanstack/react-router'
import Pubnub from 'pubnub'
import { Suspense, useEffect, useState } from 'react'
import { PubNubProvider } from 'pubnub-react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import { getUserInfo } from '@/api/user'
import { OrganizationProvider } from '@/contexts/OrganizationContext'
import { UserInfoProvider } from '@/contexts/UserInfoContext'
import { initializeApi, refreshLogin } from './api/api'
import { AUTH0_AUDIENCE } from './constants'
import router from './router'

function Loader() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={1}
      height="100vh"
    >
      <CircularProgress size={18} />
      <Typography>Loading...</Typography>
    </Box>
  )
}

function PubNubInitializer({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, user, isLoading: authLoading } = useAuth0()
  const [pubnub, setPubnub] = useState<Pubnub | null>(null)
  const [isInitializing, setIsInitializing] = useState(true)

  useEffect(() => {
    const initializePubNub = async () => {
      if (isAuthenticated && user) {
        const pubnubInstance = new Pubnub({
          publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
          subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY!,
          uuid: user.sub || 'myUniqueUserId',
        })
        setPubnub(pubnubInstance)
      } else {
        setPubnub(null)
      }
      setIsInitializing(false)
    }

    if (!authLoading) {
      initializePubNub()
    }
  }, [isAuthenticated, user, authLoading])

  if (authLoading || isInitializing) {
    return <Loader />
  }

  return isAuthenticated && pubnub ? (
    <PubNubProvider client={pubnub}>{children}</PubNubProvider>
  ) : (
    <>{children}</>
  )
}

function AppContent() {
  const auth = useAuth0()

  const { data: userData, refetch } = useQuery({
    queryKey: ['user-info-organizations'],
    queryFn: getUserInfo,
    enabled: auth.isAuthenticated,
  })
  const organizations =
    userData?.user.organization_members.map(
      (member: any) => member.organization
    ) || []

  useEffect(() => {
    initializeApi(() =>
      auth.getAccessTokenSilently({
        authorizationParams: {
          audience: AUTH0_AUDIENCE,
          scope: 'read:current_user',
        },
      })
    )
    refreshLogin(() =>
      auth.loginWithRedirect({ appState: { returnTo: window.location.href } })
    )
  }, [auth])

  return (
    <UserInfoProvider userInfo={userData} refetchUserInfo={refetch}>
      <OrganizationProvider organizations={organizations}>
        <PubNubInitializer>
          <RouterProvider router={router} context={auth} />
        </PubNubInitializer>
      </OrganizationProvider>
    </UserInfoProvider>
  )
}

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <AppContent />
    </Suspense>
  )
}
