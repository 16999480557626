import Modal from '@mui/material/Modal'
import BlurredBackdrop from '@/components/ui/modals/blurred-backdrop.tsx'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Settings, X } from 'lucide-react'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import SettingsAndMembersTabs from '@/components/organization-settings-and-members/settings-and-members-tabs.tsx'
import { usePubNub } from 'pubnub-react'
import { useEffect, useState } from 'react'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import api from '@/api/api.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useQueryClient } from '@tanstack/react-query'

const apiBaseUrl = import.meta.env.VITE_API_BASE_URL

function hasManageOrganizationPermission(
  messageEvent: any,
  currentOrganizationId: string | undefined
) {
  if (!currentOrganizationId) {
    console.log('No current organization id')
    return false
  }

  const organizationMember =
    messageEvent.message.model.organization_members.find(
      (member: any) => member.organization.id === currentOrganizationId
    )

  if (!organizationMember) {
    console.log('No organization member found')
    return false
  }

  return organizationMember.roles.some((role: any) =>
    role.permissions.includes('manage_organization')
  )
}

type SettingsAndMembersModalProps = {
  open: boolean
  onClose: () => void
}
export default function SettingsAndMembersModal({
  open,
  onClose,
}: SettingsAndMembersModalProps) {
  const queryClient = useQueryClient()
  const pubnub = usePubNub()
  const { userInfo } = useUserInfo()
  const { selectedOrganization } = useOrganization()
  const [showPermissionChangedAlert, setShowPermissionChangedAlert] =
    useState(false)

  useEffect(() => {
    const fetchAuthKeyAndInitiatePubNub = async () => {
      const channel = userInfo?.user.pubsub_channel

      if (!channel) {
        return
      }

      const body = {
        authorized_channels: [channel],
      }

      const response = await api.post(
        `${apiBaseUrl}/v1/users/pubsub_user_auth/`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      const authKey = response.data.auth_key
      pubnub.setAuthKey(authKey)

      pubnub.subscribe({ channels: [channel] })
      const listener = {
        message: async (message: any) => {
          await queryClient.invalidateQueries({
            queryKey: ['user-info-organizations'],
          })
          await queryClient.invalidateQueries({
            queryKey: ['selected-organization'],
          })
          console.log(
            'Check permissions for current org:',
            selectedOrganization?.id
          )
          if (
            selectedOrganization?.id &&
            !hasManageOrganizationPermission(message, selectedOrganization?.id)
          ) {
            onClose()
            setShowPermissionChangedAlert(true)
          }
        },
      }

      pubnub.addListener(listener)

      return () => {
        pubnub.unsubscribe({ channels: [channel] })
        pubnub.removeListener(listener)
      }
    }
    fetchAuthKeyAndInitiatePubNub()
  }, [onClose, pubnub, selectedOrganization?.id, userInfo?.user.pubsub_channel])

  const handleCloseAlert = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setShowPermissionChangedAlert(false)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: BlurredBackdrop }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '45%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            boxShadow: 24,
            pt: 2,
            width: { xs: '90%', sm: '75%', lg: '1100px' },
            height: '75%',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="p-4"
          >
            <Box display="flex" alignItems="center">
              <Settings size={26} strokeWidth={2.5} />
              <Typography variant="h2" fontWeight="bold" className="pl-4">
                Settings and Members
              </Typography>
            </Box>
            <IconButton onClick={onClose}>
              <X size={24} />
            </IconButton>
          </Box>
          <Divider />
          <SettingsAndMembersTabs />
        </Box>
      </Modal>
      <Snackbar
        open={showPermissionChangedAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="info"
          sx={{ width: '100%' }}
        >
          Your permissions have changed. You no longer have access to this
          organization&apos;s settings.
        </Alert>
      </Snackbar>
    </>
  )
}
