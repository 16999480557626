import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

import BlurredBackdrop from '@/components/ui/modals/blurred-backdrop.tsx'
import { Settings, X } from 'lucide-react'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import { useState } from 'react'
import AccountPreferencesTabs from '@/components/account-preferences/account-preferences-tabs.tsx'

type AccountPreferenceModalProps = {
  open: boolean
  onClose: (value: boolean | null) => void
}
export default function AccountPreferenceModal({
  open,
  onClose,
}: AccountPreferenceModalProps) {
  const [validationError, setValidationError] = useState<string | null>(null)

  const handleClose = () => {
    console.log('closing')
    setValidationError(null)
    onClose(false)
  }

  return (
    <Modal
      open={open}
      // onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: BlurredBackdrop }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '45%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white',
          boxShadow: 24,
          pt: 2,
          width: { xs: '90%', sm: '75%', lg: '1100px' },
          height: '75%',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="p-4"
        >
          <Box display="flex" alignItems="center">
            <Settings size={26} strokeWidth={2.5} />
            <Typography variant="h2" fontWeight="bold" className="pl-4">
              Account Preferences
            </Typography>
          </Box>

          <IconButton onClick={handleClose}>
            <X size={24} />
          </IconButton>
        </Box>
        <Divider />
        <AccountPreferencesTabs />
        {validationError}
      </Box>
    </Modal>
  )
}
