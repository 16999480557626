import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Fragment, useState, type MouseEvent } from 'react'
import { ZodError, type ZodIssue } from 'zod'

import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import {
  Check,
  CirclePlus,
  EllipsisVertical,
  SendHorizonal,
  X,
} from 'lucide-react'

import TransferOwnershipModal from '@/components/ui/modals/transfer-ownership-modal.tsx'

import {
  assignRole,
  getRoles,
  inviteUser,
  removeMember,
  resendInvite,
} from '@/api/organization_management.ts'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import { InviteUserOrganizationSchema } from '@/lib/validation-schemas.ts'
import { Organization, OrganizationMember } from '@/types.ts'
import Tooltip from '@mui/material/Tooltip'

const allowedRoles = ['Admin', 'Member']

type TeamMembersProps = {
  organization: Organization
}
export default function TeamMembers({ organization }: TeamMembersProps) {
  const { userInfo } = useUserInfo()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedMember, setSelectedMember] =
    useState<OrganizationMember | null>(null)
  const [isAddingMember, setIsAddingMember] = useState(false)
  const [newUserEmail, setNewUserEmail] = useState('')
  const [newUserRole, setNewUserRole] = useState('')
  const [userInviteSuccessful, setUserInviteSuccessful] = useState(false)
  const [transferOwnershipOpen, setTransferOwnershipOpen] = useState(false)
  const [validationError, setValidationError] = useState<ZodIssue | null>(null)

  const isOwner = userInfo?.user.organization_members
    .find((m) => m.organization.id === organization.id)
    ?.roles.some((r) => r.name === 'Owner')

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  const { refetch: resendInviteFetch } = useQuery({
    queryKey: ['resend_invite'],
    queryFn: () => {
      if (selectedMember) {
        return resendInvite({
          userId: selectedMember.user.id,
          organizationId: organization.id,
        })
      }
    },
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const inviteUserMutation = useMutation({
    mutationFn: inviteUser,
    onSuccess: async () => {
      setUserInviteSuccessful(true)
      await queryClient.invalidateQueries({
        queryKey: ['selected-organization'],
      })
    },
  })

  const assignRoleMutation = useMutation({
    mutationFn: assignRole,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['selected-organization'],
      })
    },
  })

  const removeMemberMutation = useMutation({
    mutationFn: removeMember,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['selected-organization'],
      })
    },
  })

  const queryClient = useQueryClient()

  const handleMenuOpen = (
    event: MouseEvent<HTMLButtonElement>,
    member: OrganizationMember
  ) => {
    setAnchorEl(event.currentTarget)
    setSelectedMember(member)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSelectedMember(null)
  }

  const handleCancelAddMember = () => {
    setValidationError(null)
    setIsAddingMember(false)
    setNewUserEmail('')
    setNewUserRole('')
  }

  const handleInviteUser = async () => {
    setValidationError(null)
    try {
      InviteUserOrganizationSchema.parse({
        email: newUserEmail,
        role: newUserRole,
      })
    } catch (error) {
      if (error instanceof ZodError) {
        setValidationError(error.errors[0])
      }
      return
    }
    await inviteUserMutation.mutateAsync({
      email: newUserEmail,
      roles: [newUserRole],
      organization: organization.id,
    })
  }

  const handleResendInvite = async () => {
    await resendInviteFetch()
    handleMenuClose()
  }

  const handleRemoveMember = async () => {
    if (selectedMember) {
      await removeMemberMutation.mutateAsync(selectedMember.id)
    }
    handleMenuClose()
  }

  const handleAssignRole = async (roleName: string) => {
    const role = roles?.find((r) => r.name === roleName)
    if (!selectedMember || !role) {
      return
    }
    const currentRoles = selectedMember.roles

    // if role is already assigned, remove it otherwise add it
    const newRoles = currentRoles.some((r) => r.name === roleName)
      ? currentRoles.filter((r) => r.name !== roleName)
      : [...currentRoles, role]

    await assignRoleMutation.mutateAsync({
      organization_member_id: selectedMember.id,
      roles: newRoles.map((r) => r.id),
      organization: organization.id,
    })

    handleMenuClose()
  }

  return (
    <Box p={3}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" fontWeight="bold">
          Team Members
        </Typography>
        <Button
          variant="contained"
          color="black"
          onClick={() => setIsAddingMember(true)}
          disabled={isAddingMember}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <CirclePlus size={16} />
            <Typography variant="button" textTransform="capitalize">
              Add Member
            </Typography>
          </Box>
        </Button>
      </Box>
      {isOwner && (
        <Button
          variant="outlined"
          color="error"
          onClick={() => setTransferOwnershipOpen(true)}
        >
          <Typography variant="button" textTransform="capitalize">
            Transfer Ownership
          </Typography>
        </Button>
      )}
      {isAddingMember && (
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <Typography variant="h5">New User</Typography>
          <Box display="flex" gap={1}>
            <TextField
              variant="outlined"
              label="Email"
              fullWidth
              value={newUserEmail}
              required
              onChange={(e) => {
                setNewUserEmail(e.target.value)
                setValidationError(null)
              }}
            />
            <FormControl sx={{ minWidth: 120, mr: 1 }}>
              <InputLabel>Role</InputLabel>
              <Select
                variant="outlined"
                value={newUserRole}
                label="Role"
                required
                onChange={(e) => {
                  setNewUserRole(e.target.value as string)
                  setValidationError(null)
                }}
              >
                {roles?.map((role) => {
                  if (allowedRoles.includes(role.name)) {
                    return (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    )
                  }
                })}
              </Select>
            </FormControl>
          </Box>
          {validationError && (
            <Alert severity="error">{validationError.message}</Alert>
          )}
          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              color="black"
              onClick={handleInviteUser}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <SendHorizonal size={16} />
                <Typography variant="button" textTransform="capitalize">
                  Send Invite
                </Typography>
              </Box>
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleCancelAddMember}
            >
              <Typography variant="button" textTransform="capitalize">
                Cancel
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
      <Grid container spacing={2} mt={3}>
        <Grid item xs={6} borderBottom={1}>
          <Typography variant="h5">User</Typography>
        </Grid>
        <Grid item xs={6} borderBottom={1}>
          <Typography variant="h5" textAlign="center">
            Role
          </Typography>
        </Grid>
        {organization.members
          ?.filter((member) => !member.deleted_at)
          .map((member) => {
            const cannotEditOwner =
              !isOwner && member.roles.some((r) => r.name === 'Owner')
            return (
              <Fragment key={member.id}>
                <Grid item xs={6}>
                  <Box display="flex" gap={2}>
                    <Avatar />
                    <Box display="flex" flexDirection="column">
                      <Typography variant={'body1'}>
                        {member.user.first_name && member.user.last_name
                          ? `${member.user.first_name} ${member.user.last_name}`
                          : '---'}
                      </Typography>
                      <Typography variant={'body2'} color={'textSecondary'}>
                        {member.user.email}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    justifyContent="end"
                  >
                    <Chip label={member.invite_status} />
                    <Typography variant="body1">
                      {member.roles.map((role) => role.name).join(', ')}
                    </Typography>
                    <Tooltip
                      title={
                        cannotEditOwner
                          ? 'Only the owner can edit themselves'
                          : null
                      }
                    >
                      <span>
                        <IconButton
                          onClick={(e) => handleMenuOpen(e, member)}
                          disabled={cannotEditOwner}
                        >
                          <EllipsisVertical />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Grid>
              </Fragment>
            )
          })}
      </Grid>
      <Menu
        transitionDuration={0}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <Typography fontWeight="bold" px={2}>
          Assign Role(s)
        </Typography>
        <MenuItem onClick={() => handleAssignRole('Admin')}>
          <Box display="flex" alignItems="center" gap={1}>
            {selectedMember?.roles
              .map((role) => role.name)
              .includes('Admin') ? (
              <Check size={16} />
            ) : (
              <Box sx={{ width: '16px' }} />
            )}
            <Box display="flex" flexDirection="column">
              <Typography>Admin</Typography>
              <Typography variant="caption" color="textSecondary">
                Most permissions
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleAssignRole('Member')}>
          <Box display="flex" alignItems="center" gap={1}>
            {selectedMember?.roles
              .map((role) => role.name)
              .includes('Member') ? (
              <Check size={16} />
            ) : (
              <Box sx={{ width: '16px' }} />
            )}
            <Box display="flex" flexDirection="column">
              <Typography>Member</Typography>
              <Typography variant="caption" color="textSecondary">
                Limited permissions
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        {selectedMember?.invite_status !== 'accepted' && (
          <Box>
            <Divider />
            <MenuItem onClick={handleResendInvite}>
              <Box display="flex" gap={1}>
                <SendHorizonal />
                <Typography>Resend Invite</Typography>
              </Box>
            </MenuItem>
          </Box>
        )}
        <Divider />
        <MenuItem
          onClick={handleRemoveMember}
          disabled={selectedMember?.roles.some((r) => r.name === 'Owner')}
        >
          <Box display="flex" gap={1}>
            <X />
            <Typography>Remove User from Org</Typography>
          </Box>
        </MenuItem>
      </Menu>
      <Snackbar
        open={userInviteSuccessful}
        onClose={() => setUserInviteSuccessful(false)}
        autoHideDuration={6000}
      >
        <Alert severity="success" variant="filled">
          User has been invited!
        </Alert>
      </Snackbar>
      <TransferOwnershipModal
        open={transferOwnershipOpen}
        onClose={() => setTransferOwnershipOpen(false)}
        onOwnershipTransferred={async () => {
          // TODO: display success message?
          setTransferOwnershipOpen(false)
          await queryClient.invalidateQueries({
            queryKey: ['selected-organization'],
          })
        }}
        members={organization.members || []}
        organization_id={organization.id}
      />
    </Box>
  )
}
