/**
 * Entity Types: User, Organization, File
 */
export type Organization = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  name: string
  website?: string
  created_by: User | null
  logo: GCSFile | null
  small_logo: GCSFile | null
  primary_business_type?: 'buyer' | 'seller' | 'designer' | 'hauler' | null
  verified: boolean
  legal_name: string | null
  company_structure: 'individual' | 'llc' | 's corp' | 'c corp' | null
  tax_id: string | null
  members?: OrganizationMember[]
  license?: OrganizationLicense[]
  locations?: OrganizationLocation[]
}

export type OrganizationMember = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  organization: Organization
  roles: Role[]
  invite_status: string
  user: User
}

export type OrganizationLicense = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  organization: Organization
  file: GCSFile
  created_by: User
}

export type OrganizationLocation = {
  id: string | null
  organization: Organization
  name: string
  address: ContactAddress
  phone: ContactPhone[]
  email: ContactEmail[]
  primary_location: boolean
  created_at: string
  updated_at: string | null
  deleted_at: string | null
}

type Role = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  name: string
  permissions: string[]
  custom_role: boolean
}

export type User = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  first_name: string
  last_name: string
  email: string
  organization_members: OrganizationMember[]
  pubsub_channel: string
}

export type UserInfo = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  sub: string | null
  nickname: string | null
  name: string | null
  picture: string | null
  email: string | null
  email_verified: boolean | null
  first_name: string | null
  last_name: string | null
  phone_number: string | null
  phone_number_verified: boolean | null
  user: User
}

// GCS = Google Cloud Storage
export type GCSFile = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  user_file_name: string
  file_type: string
  encoding: string
  domain: string
  get_url: string
  put_url: string
}
export interface SpreadsheetFile extends GCSFile {
  tables: string[]
}

export interface ImageFile extends GCSFile {
  url: string
  alt: string
}

/**
 * Request Body Types
 */
export type OrganizationRequestBody = {
  id?: string
  name?: string
  website?: string
  mailing_address?: OrganizationAddressRequest
  logo?: Partial<GCSFile> | null
  small_logo?: Partial<GCSFile> | null
  primary_business_type?: 'buyer' | 'seller' | 'designer' | 'hauler' | null
  verified?: boolean
  legal_name?: string | null
  company_structure?: 'individual' | 'llc' | 's corp' | 'c corp' | null
  tax_id?: string | null
}

type OrganizationAddressRequest = {
  street: string
  city: string
  state: string
  zip: string
  country: string
  id?: string | null
}

export type CreateOrganizationLocationRequestBody = {
  id?: string | null
  organization: string
  name: string
  address: ContactAddress
  phone: ContactPhone[]
  email: ContactEmail[]
  primary_location: boolean
  created_at?: string | null
  updated_at?: string | null
  deleted_at?: string | null
}

export type CreateOrganizationLicenseRequestBody = {
  organization: string
  file: Partial<GCSFile>
}

/**
 * Plant List Importer Types
 */
export type RangeDimension = {
  min: number | null
  max: number | null
}
export interface MeasurableRangeDimension extends RangeDimension {
  unit: string | null
}

export type V1Match = {
  id: number
  display_name: string
  common_names: string[]
  scientific_name: string
  genus: string
  species: string
}

export enum TrunkPlurality {
  SingleTrunk = 'Single Trunk',
  MultiTrunk = 'Multi Trunk',
}

interface TrunkForm {
  plurality: TrunkPlurality
  trunk_count: RangeDimension
  clear_trunk_size: MeasurableRangeDimension
}

export type SingleTrunk = TrunkForm & {
  plurality: TrunkPlurality.SingleTrunk
  trunk_count: RangeDimension & { min: 1; max: 1 }
}

export type MultiTrunk = TrunkForm & {
  plurality: TrunkPlurality.MultiTrunk
  cane_caliper: MeasurableRangeDimension
  trunk_count: RangeDimension
}

export type PlantListEntry = {
  id: string
  caliper: MeasurableRangeDimension
  dbh: MeasurableRangeDimension
  container: MeasurableRangeDimension
  height: MeasurableRangeDimension
  width: MeasurableRangeDimension
  surface_area: MeasurableRangeDimension
  package_count: RangeDimension
  quantity_count: RangeDimension
  shipping_container: string | null
  plant_container: string | null
  root_packaging: string | null
  trunk_form: SingleTrunk | MultiTrunk | null
  file_id: string | null
  scientific_name: string | null
  common_name: string | null
  notes: string | null
  plant_id: string | null
  shape: string[]
  palm_trunk: string[]
  plant_stage: string[]
  characteristics: string[]
  rfp_id: number[]
  parent_of_order: string | null
  deleted_at: string | null
  is_new?: boolean
}

export type PlantList = {
  id: string
  name: string
  files: GCSFile[]
  created_at: string
  updated_at: string
  entries: PlantListEntry[]
  organization: Organization
  rfp_ids: { id: number; url: string }[]
  created_by: User
}

/**
 * AI Processing Types
 */
type ProcessingError = {
  error_type: string
  message: string
  retry_iteration: number
}

export type AIProcessingData = {
  _id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  file_id: string
  user_file_name: string
  parent_plant_list_id: string
  processing_errors: ProcessingError[]
  breaking_error: ProcessingError | null
  processing_began: string | null
  processing_completed: string | null
  pubsub_channel: string
}

/**
 * Contact Types
 */
// export type Phone = {
//   number: string
//   contact_type?: 'work' | 'mobile' | 'other'
// }
// export type Email = {
//   address: string
//   contact_type?: 'work' | 'other'
// }
export type ContactType = 'organization' | 'user'
//
// export type Address = {
//   street1: string
//   street2?: string
//   city: string
//   state: string
//   zip: string
// }

export type BaseAddress = {
  id: string | null
  street: string
  city: string
  state: string
  zip: string
  country: string
  suite?: string | null
  created_at?: string | null
  updated_at?: string | null
  deleted_at?: string | null
}
export type ContactAddress = {
  id: string | null
  address_type?: 'headquarters' | 'mailing' | 'other'
  primary: boolean
  address: BaseAddress
  created_at?: string | null
  updated_at?: string | null
  deleted_at?: string | null
}
export type ContactPhone = {
  id: string | null
  number: string
  extension: string
  contact_type: 'work' | 'mobile' | 'other'
  primary: boolean
  created_at?: string | null
  updated_at?: string | null
  deleted_at?: string | null
}
export type ContactEmail = {
  id: string | null
  address: string
  contact_type: 'work' | 'other'
  primary: boolean
  created_at?: string | null
  updated_at?: string | null
  deleted_at?: string | null
}

export type MinimalOrgContactDetails = {
  id?: string | null
  organization_name: string
  owner: Organization | null
  type: 'organization'
}

export type ContactDetails = {
  organization_contact?: MinimalOrgContactDetails[]
  organization_name?: string
  first_name?: string
  last_name?: string
  address?: ContactAddress[]
  phone?: ContactPhone[]
  email?: ContactEmail[]
  type?: ContactType
  id?: string
}
type ContactCount = {
  total: number
  people: number
  organizations: number
}
export type ContactCountCollection = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  favorite: ContactCount
  all: ContactCount
  customers: ContactCount
  vendors: ContactCount
  my_team: ContactCount
}

type AddressResponse = {
  address: {
    street: string
    city: string
    state: string
    zip: string
    country: string
  }
}

export type OrganizationContact = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  type: 'organization'
  created_by: User
  user: User | null
  owner: Organization
  organization: Organization | null
  organization_name: string
  address?: AddressResponse[]
  phone?: ContactPhone[]
  email?: ContactEmail[]
  favorite: boolean
}

export type UserContact = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  type: 'user'
  created_by: User
  user: User
  owner: Organization
  organization_contact: OrganizationContact[]
  first_name: string
  last_name: string
  address?: AddressResponse[]
  phone?: ContactPhone[]
  email?: ContactEmail[]
  favorite: boolean
}

export type Contact = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  type: ContactType
  created_by: User
  owner: Organization
  organization_contact: OrganizationContact[]
  organization_name?: string
  first_name?: string
  last_name?: string
  email?: ContactEmail[]
  phone?: ContactPhone[]
  address?: ContactAddress[]
  favorite: boolean
}
export type ContactDetailsResponse = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  owner: Organization
  contact: Contact
  organization_contact?: OrganizationContact[]
}

export type ElasticsearchResponse<T> = {
  page: number
  limit: number
  last_page: number
  results_count: number
  results: T[]
}
