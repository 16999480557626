import { type PlantListEntry } from './types.ts'

export const IMAGE_FILE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'image/gif',
]
export const SPREADSHEET_FILE_TYPES = [
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const ACCEPTED_FILE_TYPES = [
  ...IMAGE_FILE_TYPES,
  ...SPREADSHEET_FILE_TYPES,
  'application/pdf',
]

export const ACCEPTED_FILE_EXTENSIONS = [
  '.jpeg',
  '.jpg',
  '.png',
  '.webp',
  '.gif',
  '.csv',
  '.xlsx',
  '.pdf',
]

export const MAX_IMAGE_DIMENSION = 8000

export const DEFAULT_PLANT_LIST_ENTRY: PlantListEntry = {
  id: '',
  caliper: { min: null, max: null, unit: null },
  dbh: { min: null, max: null, unit: null },
  container: { min: null, max: null, unit: null },
  height: { min: null, max: null, unit: null },
  width: { min: null, max: null, unit: null },
  surface_area: { min: null, max: null, unit: null },
  package_count: { min: null, max: null },
  quantity_count: { min: null, max: null },
  shipping_container: null,
  plant_container: null,
  root_packaging: null,
  trunk_form: null,
  file_id: null,
  scientific_name: null,
  common_name: null,
  notes: null,
  plant_id: null,
  shape: [],
  palm_trunk: [],
  plant_stage: [],
  characteristics: [],
  rfp_id: [],
  parent_of_order: null,
  deleted_at: null,
}

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL
export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID
export const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE

export const US_STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
] as const

export enum ContactTypes {
  USER = 'user',
  ORGANIZATION = 'organization',
}
export enum ContactAddressTypes {
  HEADQUARTERS = 'headquarters',
  MAILING = 'mailing',
  OTHER = 'other',
}
export enum ContactEmailTypes {
  WORK = 'work',
  OTHER = 'other',
}
export enum ContactPhoneTypes {
  WORK = 'work',
  MOBILE = 'mobile',
  OTHER = 'other',
}
export type ContactCategories =
  | 'all'
  | 'favorite'
  | 'customers'
  | 'vendors'
  | 'my_team'

export enum BusinessTypes {
  BUYER = 'buyer',
  SELLER = 'seller',
  DESIGNER = 'designer',
  HAULER = 'hauler',
}
