import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export default function DeliveryVehicles() {
  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        Delivery Vehicles
      </Typography>
    </Box>
  )
}
