import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { z } from 'zod'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'

import { Save } from 'lucide-react'

import BlurredBackdrop from '@/components/ui/modals/blurred-backdrop.tsx'
import ContactPersonForm from '@/components/ui/modals/contact-person-form.tsx'
import ContactOrganizationForm from '@/components/ui/modals/contact-organization-form.tsx'

import { updateContactById } from '@/api/contacts.ts'
import { ContactTypes } from '@/constants.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { convertContactToContactDetails, trimValues } from '@/lib/utils.ts'
import {
  OrganizationContactSchema,
  PersonContactSchema,
} from '@/lib/validation-schemas.ts'
import type {
  Contact,
  ContactDetails,
  ContactEmail,
  ContactPhone,
  OrganizationContact,
} from '@/types.ts'

type EditContactModalProps = {
  contact: Contact
  organizationContact?: OrganizationContact[]
  open: boolean
  onClose: () => void
}
export default function EditContactModal({
  contact,
  organizationContact,
  open,
  onClose,
}: EditContactModalProps) {
  const { selectedOrganization } = useOrganization()
  const queryClient = useQueryClient()
  const [formData, setFormData] = useState<ContactDetails>(() => {
    const details = convertContactToContactDetails(contact)
    if (organizationContact && organizationContact.length) {
      details.organization_contact = organizationContact.map((orgContact) => ({
        id: orgContact.id,
        organization_name: orgContact.organization_name,
        owner: orgContact.owner,
        type: orgContact.type,
      }))
    }
    return details
  })
  const [validationError, setValidationError] = useState<string | null>(null)

  const updateContactMutation = useMutation({
    mutationFn: updateContactById,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['contact'],
      })
      onClose()
    },
  })

  const handleFormChange = (
    newDetails: ContactPhone[] | ContactEmail[] | ContactDetails
  ) => {
    setFormData({ ...formData, ...newDetails })
    setValidationError(null)
  }

  const validateForm = (data: ContactDetails): boolean => {
    try {
      const schema =
        data.type === ContactTypes.USER
          ? PersonContactSchema
          : OrganizationContactSchema
      schema.parse(data)
      setValidationError(null)
      return true
    } catch (error) {
      if (error instanceof z.ZodError) {
        setValidationError(error.errors[0].message)
      } else {
        setValidationError('An unknown error occurred')
      }
      return false
    }
  }

  const handleSubmit = async () => {
    const trimmedFormData = trimValues(formData)
    console.log('trimmedFormData:', trimmedFormData)
    if (validateForm(trimmedFormData)) {
      try {
        await updateContactMutation.mutateAsync({
          id: contact.id,
          contactDetails: trimmedFormData,
          owner: selectedOrganization,
        })
      } catch (error) {
        console.error('Error updating contact:', error)
        setValidationError(
          `Failed to updated contact. Please try again. ${error}`
        )
      }
    }
  }

  const handleClose = () => {
    setValidationError(null)
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: BlurredBackdrop }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h2" gutterBottom>
          Edit Contact
        </Typography>
        {contact.type === ContactTypes.USER ? (
          <ContactPersonForm
            contactDetails={formData}
            onContactDetailsChange={handleFormChange}
          />
        ) : (
          <ContactOrganizationForm
            contactDetails={formData}
            onContactDetailsChange={handleFormChange}
          />
        )}

        {validationError && (
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
            {validationError}
          </Alert>
        )}

        <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
          <Button onClick={handleClose}>Cancel & Close</Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            <Box display="flex" alignItems="center" gap={1}>
              <Save size={16} /> Save Changes
            </Box>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
