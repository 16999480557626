import { type SyntheticEvent, useState } from 'react'

import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import a11yProps from '@/components/ui/a11y-props.ts'
import TabPanel from '@/components/ui/tab-panel.tsx'

import { BellIcon, Building2Icon } from 'lucide-react'
import { AccountCircleOutlined, SettingsOutlined } from '@mui/icons-material'

export default function AccountPreferencesTabs() {
  const [value, setValue] = useState(0)

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: 700,
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="account-preferences tabs"
        className="my-6 flex w-1/3 flex-grow border-r"
      >
        {[
          { label: 'My Profile', icon: <AccountCircleOutlined /> },
          { label: 'Account', icon: <SettingsOutlined /> },
          { label: 'Organizations', icon: <Building2Icon /> },
          { label: 'Notifications', icon: <BellIcon /> },
        ].map((tab, index) => (
          <Tab
            key={index}
            label={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {tab.icon}
                <span style={{ marginLeft: 16 }}>{tab.label}</span>
              </div>
            }
            sx={{
              paddingLeft: 4,
              textAlign: 'left',
              textTransform: 'none',
              alignItems: 'flex-start',
            }}
            {...a11yProps(index, 'account-preferences')}
          />
        ))}
      </Tabs>

      <TabPanel
        id="account-preferences-profile"
        index={0}
        value={value}
        className="h-full w-full overflow-y-auto"
      >
        My Profile
      </TabPanel>

      <TabPanel
        id="account-preferences-account"
        index={1}
        value={value}
        className="h-full w-full overflow-y-auto"
      >
        Account
      </TabPanel>

      <TabPanel
        id="account-preferences-organizations"
        index={2}
        value={value}
        className="h-full w-full overflow-y-auto"
      >
        Organizations
      </TabPanel>

      <TabPanel
        id="account-preferences-notifications"
        index={3}
        value={value}
        className="w-full"
      >
        Notifications
      </TabPanel>
    </Box>
  )
}
