import { Organization } from '@/types.ts'
import React, {
  createContext,
  useState,
  type ReactNode,
  useEffect,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import { getOrganizationById } from '@/api/organization_management.ts'

type OrganizationContextType = {
  organizations: Organization[] | null
  selectedOrganizationId: string | null
  setSelectedOrganizationId: (id: string | null) => void
  selectedOrganization: Organization | null
}

export const OrganizationContext =
  createContext<OrganizationContextType | null>(null)

type OrganizationProviderProps = {
  children: ReactNode
  organizations: Organization[] | null
}
export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({
  children,
  organizations,
}) => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    string | null
  >(null)
  const { data: organizationData, refetch } = useQuery({
    queryKey: ['selected-organization'],
    queryFn: () => {
      if (selectedOrganizationId) {
        return getOrganizationById(selectedOrganizationId)
      }
      return null
    },
    enabled: !!selectedOrganizationId,
  })
  useEffect(() => {
    if (refetch) {
      refetch()
    }
  }, [selectedOrganizationId, refetch])

  useEffect(() => {
    if (organizations && !selectedOrganizationId) {
      setSelectedOrganizationId(organizations[0]?.id || null)
    }
  }, [organizations, selectedOrganizationId])

  return (
    <OrganizationContext.Provider
      value={{
        selectedOrganization: organizationData || null,
        selectedOrganizationId,
        setSelectedOrganizationId,
        organizations,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}
