import { createTheme, PaletteColorOptions } from '@mui/material'

declare module '@mui/material/styles' {
  interface CustomPalette {
    black: PaletteColorOptions
    lightGrey: PaletteColorOptions
    mediumGrey: PaletteColorOptions
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true
    lightGrey: true
    mediumGrey: true
  }
}

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } })

const theme = createTheme({
  palette: {
    black: createColor('#000000'),
    lightGrey: createColor('#f5f5f5'),
    mediumGrey: createColor('#929292'),
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2rem',
      '@media (min-width:1024px)': {
        fontSize: '2rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '2.5rem',
      },
      // '@media (min-width:1536px)': {
      //   fontSize: '3rem',
      // },
    },
    h2: {
      fontSize: '1.5rem',
      '@media (min-width:1024px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1.75rem',
      },
      // '@media (min-width:1536px)': {
      //   fontSize: '2rem',
      // },
    },
    h3: {
      fontSize: '1.25rem',
      '@media (min-width:1024px)': {
        fontSize: '1.25rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1.5rem',
      },
      // '@media (min-width:1536px)': {
      //   fontSize: '1.75rem',
      // },
    },
    h4: {
      fontSize: '1rem',
      '@media (min-width:1024px)': {
        fontSize: '1rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1.25rem',
      },
      // '@media (min-width:1536px)': {
      //   fontSize: '1.5rem',
      // },
    },
    h5: {
      fontSize: '1rem',
    },
  },
})

export default theme
