import Box from '@mui/material/Box'
import { useState, ChangeEvent } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

type DataSharingProps = {
  switchToOrganizationTab: (value: boolean) => void
}

export default function DataSharing({
  switchToOrganizationTab,
}: DataSharingProps) {
  const [plantbidAccountId, setPlantbidAccountId] = useState('')

  const handleExport = () => {
    console.log('Export')
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPlantbidAccountId(e.target.value)
  }

  return (
    <Box className="flex flex-col gap-2 p-4">
      <h2 className="text-lg font-semibold">Data Sharing</h2>
      <div>Export an existing Plantbid account&apos;s data</div>
      <div>
        You must be an admin of BOTH account to export an existing
        organization&apos;s data, otherwise you can request permission from
        other account&apos;s admin.
      </div>
      <Grid container spacing={2} marginY={1}>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            name="account_id"
            label="Plantbid Account ID"
            placeholder="Type ID"
            fullWidth
            value={plantbidAccountId}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <button
            onClick={() => handleExport()}
            className="flex items-center rounded-md bg-black p-4 text-white"
          >
            Continue to Export
          </button>
        </Grid>
      </Grid>
      <div
        className="cursor-pointer text-xs underline"
        onClick={() => switchToOrganizationTab(true)}
      >
        Where do I find my Plantbid account ID ?
      </div>
    </Box>
  )
}
